import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
    selector: "[s25-ng-click-outside]",
    standalone: true,
})
export class S25ClickOutsideDirective {
    @Output() clickOutside = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) {}

    @HostListener("document:click", ["$event.target"])
    public onClick(target: Element) {
        const inside = this.elementRef.nativeElement.contains(target);
        if (!inside) this.clickOutside.emit();
    }
}
