import { S25Util } from "../../util/s25-util";
import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
    ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { AiExpressSchedulingResponse, Message, S25ChatbotService } from "./s25.chatbot.service";
import { S25WsSpace, SpaceService } from "../../services/space.service";
import { TelemetryService } from "../../services/telemetry.service";
import { S25ChatbotComponent } from "./s25.chat.bot.component";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { UserprefService } from "../../services/userpref.service";

export type ReservationAttemptDetails = {
    selectedSpace: S25WsSpace;
    selectedDetails: AiExpressSchedulingResponse;
};

@TypeManagerDecorator("s25-ng-ai-express-scheduling-chatbot")
@Component({
    selector: "s25-ng-ai-express-scheduling-chatbot",
    template: `
        @if (init) {
            <s25-ng-ai-chatbot
                #chatbot
                [welcomeMessage]="
                    'Provide me with the date, start/end time, name, and maximum headcount of your new event, and I\\'ll provide you with a list of spaces that are available!'
                "
                [isLoading]="isLoading"
                (messageThreadSent)="handleMessageThreadSent($event)"
            ></s25-ng-ai-chatbot>
            @if (spaceOptions.length) {
                <ul>
                    @for (space of spaceOptions; track space.space_id) {
                        <li class="reserve-option">
                            <div class="space-info">
                                <span class="space-name">{{ space.space_name }}</span>
                                <span class="ngFinePrint">Capacity: {{ space.max_capacity }}</span>
                            </div>
                            <button
                                class="aw-button aw-button--outline"
                                [disabled]="isLoading"
                                (click)="callForSave(space)"
                            >
                                Reserve
                            </button>
                        </li>
                    }
                </ul>
            }
        }
    `,
    styles: `
        .reserve-option {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid #e0e0e0;
            text-align: start;
        }

        .space-info {
            display: flex;
            flex-direction: column;
        }

        .space-name {
            font-weight: bold;
            font-size: 16px;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
    imports: [FormsModule, S25ChatbotComponent],
    standalone: true,
})
export class S25ExpressSchedulingChatbotComponent implements OnInit {
    @Input() onChange: (reservationAttemptDetails: ReservationAttemptDetails) => AiExpressSchedulingResponse =
        undefined;
    @Output() modelValueChange = new EventEmitter();

    @ViewChild("chatbot") chatbot!: S25ChatbotComponent;

    init: boolean = false;
    isLoading: boolean = true;

    // The start/end date-time / headcount / event name the openAI model believes the user is trying to reserve
    selectedDetails: AiExpressSchedulingResponse;

    // The available spaces the spaceService returns
    spaceOptions: Array<S25WsSpace> = [];

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.init = true;
        this.isLoading = false;
        this.cd.detectChanges();
    }

    async handleMessageThreadSent(thread: Message[]) {
        // If space options have been offered, clear them
        if (this.spaceOptions.length) {
            this.spaceOptions = [];
            this.cd.detectChanges();
        }

        this.isLoading = true;

        try {
            // Await AI response
            const resp = await S25ChatbotService.sendMessageThread(thread);

            // If our response has the values we need to move to the reservation step...
            if (this.checkResponse(resp)) {
                let userTimeFormat = await UserprefService.getS25DateTimeformat();
                let parsedStart = S25Datefilter.transform(resp.startDate, userTimeFormat);
                let parsedEnd = S25Datefilter.transform(resp.endDate, userTimeFormat);

                // Save the date/time/name
                this.selectedDetails = resp;
                this.chatbot.addResponse(
                    `Here's what I've gathered:\nEvent name: ${resp.eventName} \n${parsedStart} - ${parsedEnd}\nCapacity: ${resp.maximumRoomCapacityNeeded}.\nYou may select a space below, or continue the conversation if you need to make changes.`,
                );

                await this.getAvailableSpaces();
            } else {
                this.chatbot.addResponse(resp.chatResponse);
            }
            this.isLoading = false;
        } catch {
            this.chatbot.addResponse("An error has occurred. Please try again.");
        }
    }

    public onChangeHandler(reservationAttemptDetails: ReservationAttemptDetails) {
        this.onChange && S25Util.isFunction(this.onChange) && this.onChange(reservationAttemptDetails);
        //this.modelValueChange.emit(reservationAttemptDetails);
        this.cd.detectChanges();
    }

    checkResponse(response: AiExpressSchedulingResponse) {
        // If we have all the required information
        return response.startDate && response.endDate && response.eventName && response.maximumRoomCapacityNeeded;
    }

    async getAvailableSpaces() {
        // This is used to store the ids of each location for use in the spaceservice call
        let locationItemIds: Array<number> = [];
        let expressScheduleMap = new Map<number, S25WsSpace["direct_schedule"]>();

        // Get all locations that allow the user to express schedule
        await SpaceService.getExpressSpaces(
            S25Util.date.parse(this.selectedDetails.startDate),
            S25Util.date.parse(this.selectedDetails.endDate),
        ).then((spaces) => {
            if (spaces && spaces.length) {
                // Store locations and map direct_schedule object by itemId for use in duration verification and itemTypeId
                spaces.map((space) => {
                    expressScheduleMap.set(space.space_id, space.direct_schedule); // Map direct_schedule_type_id by space_id
                    return locationItemIds.push(space.space_id);
                });
            } else {
                alert("No express locations found.");
            }
        });

        await SpaceService.getSpaceDatesAvailability(locationItemIds, [
            { startDt: this.selectedDetails.startDate, endDt: this.selectedDetails.endDate },
        ]).then((spaces) => {
            if (spaces.spaces.space && spaces.spaces.space.length) {
                // Map spaces to spaceOptions and include itemTypeId from the map
                const allExpressSpaces = spaces.spaces.space.map((space) => ({
                    ...space,
                    eventTypeId: expressScheduleMap.get(space.space_id).direct_schedule_type_id, // Lookup directScheduleTypeId by space_id
                    maxEventDuration: expressScheduleMap.get(space.space_id).direct_schedule_duration, // Lookup max duration by space_id
                    itemId: space.space_id, // This needs to be itemId for use in the express-scheduling.js postExpressEvent function
                }));

                // Filter out any express spaces with a conflict.
                this.spaceOptions = allExpressSpaces.filter((space) => space.has_conflicts === "F");

                // Filter out any spaces that don't meet our capacity requirements
                this.spaceOptions = this.spaceOptions.filter((space) => {
                    return space.max_capacity >= this.selectedDetails.maximumRoomCapacityNeeded;
                });

                // Filter out any spaces that don't meet our requested event duration requirements
                var difference = Date.parse(this.selectedDetails.endDate) - Date.parse(this.selectedDetails.startDate); // This will give difference in milliseconds
                var resultInMinutes = Math.round(difference / 60000); // This will give difference in minutes

                this.spaceOptions = this.spaceOptions.filter((space) => {
                    return space.maxEventDuration >= resultInMinutes || space.maxEventDuration == 0; // A limit of 0 means there is no limit. ¯\_(ツ)_/¯
                });

                // Sort by max capacity
                this.spaceOptions.sort((a, b) => a.max_capacity - b.max_capacity);

                // Limit this to just 5 spaces (for now?)
                this.spaceOptions = this.spaceOptions.slice(0, 5);

                if (!this.spaceOptions.length) {
                    alert("There are no available spaces that meet your criteria.");
                }
            } else {
                alert("No spaces available.");
            }
            this.cd.markForCheck();
        });
    }

    callForSave(space: S25WsSpace) {
        // If start datetime is after end datetime
        if (Date.parse(this.selectedDetails.startDate) > Date.parse(this.selectedDetails.endDate)) {
            return alert("Your event starts before it ends.");
        }

        const payload = {
            selectedSpace: space,
            selectedDetails: this.selectedDetails,
        };
        this.onChangeHandler(payload);
        TelemetryService.sendWithSub("ExpressScheduling", "AI", "Save");
    }

    //     async reserveExpressScheduleSpace(space) {
    //         // This clears the buttons from the window, but should probably be done in a different way.
    //         // The edge case here is if the user selects a space that fails, but they don't change their selected time or event name, then they no longer have options, and it costs another batch of service calls
    //         this.spaceOptions = [];
    //         this.cd.detectChanges();
    //
    //         // Check if "startsInFuture". This could be done by AI, but no harm being redundant here.
    //         // This might have TZ issues?
    //         if (Date.now() > Date.parse(this.selectedDetails.startDate)) {
    //             return alert("Selected start time is in the past.");
    //         }
    //
    //         // Check that the name is not null OR (private). This could be done by AI, but no harm being redundant here.
    //         if (
    //             !this.selectedDetails.eventName || // Ensure name is non-null
    //             this.selectedDetails.eventName.toLowerCase().trim() === "(private)"
    //         ) {
    //             return alert("Selected event name is invalid.");
    //         }
    //
    //         // Check that the selected space is an express location.
    //         // How did we get this far if it isn't?
    //         if (!space.directScheduleTypeId) {
    //             return alert("Please select an express location.");
    //         }
    //
    //         if (!space.directScheduleTypeId) {
    //             return alert("No express event type id found. Please re-select or try another location.");
    //         }
    //
    //         // Re-check day/time availability, in case something has changed
    //         if (
    //             !SpaceService.isExpressAvail(
    //                 space.space_id,
    //                 S25Util.date.parse(this.selectedDetails.startDate),
    //                 S25Util.date.parse(this.selectedDetails.endDate),
    //             )
    //         ) {
    //             return alert("Selected location is no longer available.");
    //         }
    //
    //         // Add the cabinet select modal???
    //
    //         // Call the event service POST
    //         await EventService.postExpressEvent(
    //             this.selectedDetails.eventName, // The event name
    //             space.space_id, // The location/space id
    //             space.directScheduleTypeId, // Event type id
    //             null, // Scheduler id - Where does this come from?
    //             2, // State
    //             this.selectedDetails.startDate,
    //             this.selectedDetails.endDate,
    //             true,
    //             133330, // This needs to come from the cabinet modal
    //         ).then((response) => {
    //             let eventId = response.events.event[0].event_id;
    //
    //             // Navigate the user to their event
    //             StateService.gotoItem({
    //                 itemTypeId: 1,
    //                 itemId: eventId,
    //                 forceView: "details",
    //             });
    //         });
    //     }
}
